export const ActionTypes = [
  "未定義",
  "DevToolからの付与",
  "ガチャ購入",
  "ショップ購入",
  "パネル単体強化",
  "パネル一括強化",
  "MCカードレベル強化",
  "メモリーカードレベル強化",
  "メモリーカードランク強化",
  "ログインボーナス受取",
  "バトル開始",
  "誕生日ログインボーナス受取",
  "アカウント移行復元",
  "バトルパスレベルアップ",
  "ミッション報酬受取",
  "ミッション達成数報酬受取",
  "ミッション報酬全受取",
  "バトルスキップ",
  "タワーバトル開始",
  "ACTアイテムプレゼント",
  "ボックスガチャ実行",
  "プロフィール変更",
  "バトルコンティニュー",
  "バトルリタイア",
  "タワーバトルコンティニュー",
  "タワーバトルリタイア",
  "バトル終了",
  "ACT開始",
  "ACT開始AUTO",
  "ACTキャラクター変更",
  "ACTアイテム受取",
  "ACTアイテム全受取",
  "アプリ内課金コンテンツ受取",
  "アプリペイ付与",
  "お知らせ既読",
  "バトルプレイ回数回復",
  "バトルスタミナ回復",
  "カードストーリーエピソード閲覧",
  "ホームカード変更",
  "アイテム消費",
  "ミッション進捗更新",
  "パーティ変更",
  "プレゼント全受取",
  "プレゼント受取",
  "プロフィール誕生日登録",
  "PvPバトル終了エラー回復",
  "PvP階層確定ステージング",
  "PvPエントリー",
  "PvPバトル終了",
  "クエストストーリーエピソード閲覧",
  "特定ミッションチェック",
  "タワーバトル終了",
  "チュートリアルガチャ実行",
  "チュートリアル進捗更新",
  "ユーザー作成",
  "タワースコア報酬受取",
  "ログインミッション更新",
  "ボックスガチャリセット",
  "タワー制限編成更新",
  "バトルパス報酬全受取",
  "バトルパス報酬受取",
  "グローバルプレゼント受取",
  "PvPスタミナ回復",
];
