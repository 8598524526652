import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  SelectField,
} from "react-admin";
import {
  CommonList,
  DateTimeWithSecInput,
  EditToolbar,
  EditTitle,
  YMDHMSDateField,
  NumField,
} from "../Common";
import { ContentTypes } from "common/ContentType";

export const GlobalPresentList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="ID" />
      <SelectField
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
      />
      <NumField source="ContentId" label="コンテンツID" />
      <NumField source="ContentNum" label="個数" />
      <YMDHMSDateField source="OpenAt" label="付与開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="受取期限" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

export const GlobalPresentEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="全体付与" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <SelectInput
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
      />
      <NumberInput source="ContentId" label="コンテンツID" />
      <NumberInput source="ContentNum" label="個数" />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" />
      <TextInput
        source="CustomMessage"
        label="カスタムメッセージ"
        helperText="ユーザーに表示するメッセージ。省略可。"
      />
    </SimpleForm>
  </Edit>
);

export const GlobalPresentCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <SelectInput
        source="ContentType"
        label="コンテンツタイプ"
        choices={ContentTypes}
      />
      <NumberInput source="ContentId" label="コンテンツID" />
      <NumberInput source="ContentNum" label="個数" />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" />
      <TextInput
        source="CustomMessage"
        label="カスタムメッセージ"
        helperText="ユーザーに表示するメッセージ。省略可。"
      />
    </SimpleForm>
  </Create>
);
