import { Datagrid, NumberInput, TextField, ReferenceField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userItemFilters = [
  <NumberInput source="ItemId" label="ItemId" />,
  <NumberInput source="Num" label="所持数" />,
];

export const UserItemList = (props: any) => (
  <CommonList {...props} addFilters={userItemFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ItemId" label="ItemId" />
      <ReferenceField source="ItemId" reference="Item" label="アイテム名">
        <TextField source="name" />
      </ReferenceField>
      <NumField source="Num" label="所持数" />
    </Datagrid>
  </CommonList>
);
