import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="CharacterCardId" label="MCカードID" />,
  <NumberInput source="PanelId" label="パネルID" />,
]);

export const LogUserCharacterPanelList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="CharacterCardId" label="MCカードID" />
    <NumField source="PanelId" label="パネルID" />
  </CommonLogUserList>
);
