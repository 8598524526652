import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="EquipmentCardId" label="メモリーカードID" />,
  <NumberInput source="EquipmentCardNumber" label="メモリーカード番号" />,
  <NumberInput source="Rank" label="ランク" />,
  <NumberInput source="BeforeRank" label="ランクBefore" />,
  <NumberInput source="AfterRank" label="ランクAfter" />,
]);

export const LogUserEquipmentCardRankList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="EquipmentCardId" label="メモリーカードID" />
    <NumField source="EquipmentCardNumber" label="メモリーカード番号" />
    <NumField source="Rank" label="ランク" />
    <NumField source="BeforeRank" label="ランクBefore" />
    <NumField source="AfterRank" label="ランクAfter" />
  </CommonLogUserList>
);
