import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="EquipmentCardId" label="カードID" />,
  <NumberInput source="EquipmentCardNumber" label="カードNO" />,
  <NumberInput source="Count" label="獲得数" />,
]);

export const LogUserEquipmentCardList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="EquipmentCardId" label="カードID" />
    <NumField source="EquipmentCardNumber" label="カードNO" />
    <NumField source="Count" label="獲得数" />
  </CommonLogUserList>
);
