export enum Role {
  Admin = "admin",
  Member = "member",
  Reader = "reader",
}

export const roleNames = [
  { id: Role.Admin, name: "管理者" },
  { id: Role.Member, name: "メンバー" },
  { id: Role.Reader, name: "閲覧者" },
];

export const rolesAdmin = [Role.Admin];
export const rolesMember = [Role.Admin, Role.Member];
export const rolesReader = [Role.Admin, Role.Member, Role.Reader];
