import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="CharacterCardId" label="カードID" />,
  <NumberInput source="Exp" label="Exp" />,
  <NumberInput source="BeforeExp" label="ExpBefore" />,
  <NumberInput source="AfterExp" label="ExpAfter" />,
]);

export const LogUserCharacterCardExpList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="CharacterCardId" label="カードID" />
    <NumField source="Exp" label="Exp" />
    <NumField source="BeforeExp" label="ExpBefore" />
    <NumField source="AfterExp" label="ExpAfter" />
  </CommonLogUserList>
);
