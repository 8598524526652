export const ContentTypes = [
  { id: 1, name: "キャラクター" },
  { id: 2, name: "装備" },
  { id: 3, name: "アイテム" },
  { id: 4, name: "マネー" },
  { id: 5, name: "無償石" },
  { id: 6, name: "有償石" },
  { id: 7, name: "クエストストーリー" },
  { id: 8, name: "称号" },
];
