import { rolesAdmin, rolesMember, rolesReader } from "../config/role";

export const AdminElm = (permissions: any, element: any) => {
  return rolesAdmin.includes(permissions.role) ? element : null;
};

export const MemberElm = (permissions: any, element: any) => {
  return rolesMember.includes(permissions.role) ? element : null;
};

export const ReaderElm = (permissions: any, element: any) => {
  return rolesReader.includes(permissions.role) ? element : null;
};
