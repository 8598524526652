import { Datagrid, NumberInput, TextField, ReferenceField } from "react-admin";
import {
  CommonList,
  DateTimeWithSecInput,
  YMDHMSDateField,
  NumField,
} from "../Common";

const userShopMerchandiseFilters = [
  <NumberInput source="ShopMerchandiseId" label="ShopMerchandiseId" />,
  <NumberInput source="PurchaseCount" label="購入数" />,
  <NumberInput source="TotalPurchaseCount" label="総購入数" />,
  <DateTimeWithSecInput
    source="LastPurchasedAt_from"
    label="最終購入日時From"
  />,
  <DateTimeWithSecInput source="LastPurchasedAt_to" label="最終購入日時To" />,
];

export const UserShopMerchandiseList = (props: any) => (
  <CommonList {...props} addFilters={userShopMerchandiseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ShopMerchandiseId" label="ShopMerchandiseId" />
      <ReferenceField
        source="ShopMerchandiseId"
        reference="ShopMerchandise"
        label="商品名"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumField source="PurchaseCount" label="購入数" />
      <NumField source="TotalPurchaseCount" label="総購入数" />
      <YMDHMSDateField source="LastPurchasedAt" label="最終購入日時" />
    </Datagrid>
  </CommonList>
);
