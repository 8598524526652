import {
  Datagrid,
  FunctionField,
  NumberInput,
  TextField,
  TextInput,
} from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";
import { ActionTypes } from "../../common/ActionType";

const logUserActionFilters = [
  <TextInput source="Id" label="Id" />,
  <NumberInput source="ActionType" label="ActionType" />,
  <DateTimeWithSecInput source="CreatedAt_from" label="作成日時from" />,
  <DateTimeWithSecInput source="CreatedAt_to" label="作成日時to" />,
];

export const LogUserActionList = (props: any) => (
  <CommonList
    {...props}
    addFilters={logUserActionFilters}
    sort={{ field: "CreatedAt", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="Id" />
      <TextField source="UserId" label="ユーザーID" />
      <FunctionField
        label="ActionType"
        render={(r: any) => `${r.ActionType}: ${ActionTypes[r.ActionType]}`}
      />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
