import { NumberInput, TextField, TextInput } from "react-admin";
import { CommonLogUserList, CommonLogUserFilter, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <TextInput source="UserStoneDetailId" label="UserStoneDetailId" />,
  <NumberInput source="Count" label="獲得数" />,
  <NumberInput source="BeforeCount" label="獲得数Before" />,
  <NumberInput source="AfterCount" label="獲得数After" />,
]);

export const LogUserStoneList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <TextField source="UserStoneDetailId" label="UserStoneDetailId" />
    <NumField source="Count" label="獲得数" />
    <NumField source="BeforeCount" label="獲得数Before" />
    <NumField source="AfterCount" label="獲得数After" />
  </CommonLogUserList>
);
