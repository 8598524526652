import {
  Datagrid,
  NumberInput,
  TextField,
  TextInput,
  RadioButtonGroupInput,
} from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userPresentFilters = [
  <RadioButtonGroupInput
    source="IsAvailable"
    label="IsAvailable"
    choices={[
      { id: "on", name: "On" },
      { id: "off", name: "Off" },
    ]}
    alwaysOn
  />,
  <TextInput source="ContentType" label="ContentType" />,
  <NumberInput source="ContentId" label="ContentId" />,
];

export const UserPresentList = (props: any) => (
  <CommonList
    {...props}
    addFilters={userPresentFilters}
    filterDefaultValues={{ IsAvailable: "on" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="プレゼントID" />
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="ContentType" label="ContentType" />
      <NumField source="ContentId" label="ContentId" />
      <NumField source="ContentNum" label="ContentNum" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
      <YMDHMSDateField source="ExpiredAt" label="期限" />
      <YMDHMSDateField source="ReceivedAt" label="受取日時" />
    </Datagrid>
  </CommonList>
);
