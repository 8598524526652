import React, { useState } from "react";
import {
  BooleanField,
  Datagrid,
  NumberInput,
  TextField,
  BooleanInput,
  ReferenceField,
  Show,
  SimpleShowLayout,
  ShowButton,
  FunctionField,
} from "react-admin";
import {
  CommonList,
  DateTimeWithSecInput,
  YMDHMSDateField,
  NumField,
} from "../Common";
import { GetMasterData } from "../../common/MasterData";
import { RarityTypeString } from "../../common/RarityType";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const userEquipmentCardFilters = [
  <NumberInput source="EquipmentCardId" label="EquipmentCardId" />,
  <NumberInput source="UserEquipmentCardNumber" label="NO" />,
  <NumberInput source="Rank" label="覚醒段階" />,
  <NumberInput source="Exp" label="EXP" />,
  <BooleanInput source="IsFavorite" label="お気に入り" />,
  <DateTimeWithSecInput source="AcquiredAt_from" label="獲得日時From" />,
  <DateTimeWithSecInput source="AcquiredAt_to" label="獲得日時To" />,
];

export const UserEquipmentCardList = (props: any) => (
  <CommonList {...props} addFilters={userEquipmentCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="EquipmentCardId" label="EquipmentCardId" />
      <ReferenceField
        source="EquipmentCardId"
        reference="EquipmentCard"
        label="メモリーカード名"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumField source="UserEquipmentCardNumber" label="NO" />
      <FunctionField
        label="覚醒段階"
        render={(record: any) => record.Rank + 1}
      />
      <NumField source="Exp" label="EXP" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <YMDHMSDateField source="AcquiredAt" label="獲得日時" />
      <ShowButton />
    </Datagrid>
  </CommonList>
);

export const UserEquipmentCardShow = (props: any) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // 前のページに戻る
  };

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleToggle = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Show {...props} resource="UserEquipmentCardDetail">
      <SimpleShowLayout divider={<Divider />}>
        <NumField
          source="CalculatedEquipmentCard.EquipmentCardId"
          label="EquipmentCardId"
        />
        <ReferenceField
          source="CalculatedEquipmentCard.EquipmentCardId"
          reference="EquipmentCard"
          label="メモリーカード名"
        >
          <TextField source="card_name" />
        </ReferenceField>
        <ReferenceField
          source="CalculatedEquipmentCard.EquipmentCardId"
          reference="EquipmentCard"
          label="レアリティ"
        >
          <FunctionField
            render={(record: any) => RarityTypeString(record.rarity)}
          />
        </ReferenceField>
        <FunctionField
          label="覚醒段階"
          render={(record: any) => record.CalculatedEquipmentCard.Rank + 1}
        />
        <NumField source="CalculatedEquipmentCard.Level" label="レベル" />
        <NumField
          source="CalculatedEquipmentCard.MaxLevel"
          label="最大レベル"
        />
        <NumField source="CalculatedEquipmentCard.Exp" label="EXP" />
        <NumField source="CalculatedEquipmentCard.HP" label="HP" />
        <NumField source="CalculatedEquipmentCard.ATK" label="ATH" />
        <NumField source="CalculatedEquipmentCard.DEF" label="DEF" />
        <FunctionField
          label="スキル情報"
          render={(record: any) => {
            const isExpanded = expandedRows["skill"] || false;

            return (
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleToggle("skill")}
                  endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {isExpanded ? "閉じる" : "表示"}
                </Button>
                {isExpanded && (
                  <EquipmentCardSkillField
                    data={record.CalculatedEquipmentCard}
                  />
                )}
              </div>
            );
          }}
        />
      </SimpleShowLayout>
      <Button onClick={handleBackClick}>戻る</Button>
    </Show>
  );
};

const EquipmentCardSkillField = (props: any) => {
  const equipmentCard = GetMasterData(
    "EquipmentCard",
    props.data.EquipmentCardId
  );
  if (!equipmentCard) {
    return null;
  }

  const equipmentCardSkills = [];
  if (equipmentCard.passive_skill_id_1 > 0) {
    equipmentCardSkills.push({
      passiveSkillId: equipmentCard.passive_skill_id_1,
      passiveSkillLevel: props.data.PassiveSkillLevel1,
    });
  }
  if (equipmentCard.passive_skill_id_2 > 0) {
    equipmentCardSkills.push({
      passiveSkillId: equipmentCard.passive_skill_id_2,
      passiveSkillLevel: props.data.PassiveSkillLevel2,
    });
  }
  if (equipmentCard.passive_skill_id_3 > 0) {
    equipmentCardSkills.push({
      passiveSkillId: equipmentCard.passive_skill_id_3,
      passiveSkillLevel: props.data.PassiveSkillLevel3,
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "150px" }} align="right">
              パッシブスキルID
            </TableCell>
            <TableCell sx={{ width: "200px" }}>スキル名</TableCell>
            <TableCell sx={{ width: "150px" }} align="right">
              スキルレベル
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {equipmentCardSkills?.map((equipmentCardSkill: any) => {
            const skill = GetMasterData(
              "PassiveSkill",
              equipmentCardSkill.passiveSkillId
            );
            return (
              <TableRow
                key={equipmentCardSkill.passiveSkillId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">
                  {equipmentCardSkill.passiveSkillId}
                </TableCell>
                <TableCell>{skill?.name}</TableCell>
                <TableCell align="right">
                  {equipmentCardSkill.passiveSkillLevel}
                </TableCell>
                <TableCell />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
