import { Datagrid, TextField, NumberInput, ReferenceField } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userMissionAchievedCountFilters = [
  <NumberInput
    source="MissionAchievedCountId"
    label="MissionAchievedCountId"
  />,
  <NumberInput source="Progress" label="進捗" />,
];

export const UserMissionAchievedCountList = (props: any) => (
  <CommonList {...props} addFilters={userMissionAchievedCountFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField
        source="MissionAchievedCountId"
        label="MissionAchievedCountId"
      />
      <ReferenceField
        source="MissionAchievedCountId"
        reference="MissionAchievedCount"
        label="ミッション名"
      >
        <TextField source="text" />
      </ReferenceField>
      <NumField source="Progress" label="進捗" />
      <YMDHMSDateField source="LastProgressedAt" label="進捗更新日" />
      <YMDHMSDateField source="RewardReceivedAt" label="報酬受取日時" />
    </Datagrid>
  </CommonList>
);
