import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="MissionId" label="ミッションID" />,
]);

export const LogUserMissionRewardList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="MissionId" label="ミッションID" />
  </CommonLogUserList>
);
