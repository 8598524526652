import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput source="EquipmentCardId" label="メモリーカードID" />,
  <NumberInput source="EquipmentCardNumber" label="メモリーカード番号" />,
  <NumberInput source="Exp" label="Exp" />,
  <NumberInput source="BeforeExp" label="ExpBefore" />,
  <NumberInput source="AfterExp" label="ExpAfter" />,
]);

export const LogUserEquipmentCardExpList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="EquipmentCardId" label="メモリーカードID" />
    <NumField source="EquipmentCardNumber" label="メモリーカード番号" />
    <NumField source="Exp" label="Exp" />
    <NumField source="BeforeExp" label="ExpBefore" />
    <NumField source="AfterExp" label="ExpAfter" />
  </CommonLogUserList>
);
